export default {
    en: {
        title: "My Classes",
        titleLesson: "Please Choose The Month Of Lessons",
        cancel: "Cancel",
        confirm: "Confirm",
        quitTitle: "Quit Class",
        quitMessage: "Are you sure to quit this class [@@]? Please note that quiting this class will not initiate auto-refund",
        quitSuccess: "Class Dropped"
    },
    zh: {
        title: "我的课程",
        titleLesson: "请选择要管理的课堂月份",
        cancel: "取消",
        confirm: "确认",
        quitTitle: "退出课程",
        quitMessage: "确定要退出 [@@] 课程吗？ 请注意退出此课程后并不会自动退款，退款事宜请联系管理员",
        quitSuccess: "已退出课程"
    }
}