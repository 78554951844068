export default {
    en: {
        teachers: "TEACHERS",
        plan: "SCHEDULE",
        key: "KEY INFO",
        enroll: "Allow Enroll",
        search: "Allow Search",
        auto: "Auto Admit",
        students: "Total Enroll",
        buttonRecord: "Payments",
        buttonPay: "Pay Fees",
        buttonQuit: "Quit Class",
        buttonLesson: "Lesson Hours",
        buttonStudents: "Manage Students",
        admit: "Admitted!",
        notAdmit: "Waiting admission"
    },
    zh: {
        teachers: "老师",
        plan: "课程",
        key: "重点信息",
        enroll: "允许报名",
        search: "允许搜索",
        auto: "自动录取",
        students: "已报名学生",
        buttonRecord: "缴费记录",
        buttonPay: "支付费用",
        buttonQuit: "退出课程",
        buttonLesson: "管理课时",
        buttonStudents: "管理学生",
        admit: "已确认录取",
        notAdmit: "等待录取"
    }
}