import axios from "axios";
import Env from "@/logics/Envs.js";

export default async function (classId, teachers) {
    try {
        const toSend = new FormData();
        toSend.append("classId", classId);
        toSend.append("json", JSON.stringify(teachers));

        const res = await axios.post(Env.apiPath + "updateClassTeachers", toSend);
        return res.data.error;
    } catch (err) {
        alert(err);
    }
}