import axios from "axios";
import Env from "@/logics/Envs.js";

export default async function (classId, flagName, flagValue) {
    try {
        const params = { classId, flagName, flagValue };
        const res = await axios.get(Env.apiPath + "setClassFlag", { params });
        return res.data.error;
    } catch (err) {
        return err;
    }
}