<template>
    <div class="pt-32 flex flex-col items-center" v-if="user">
        <div class="w-full flex flex-col items-center mb-12 px-4" v-if="isManager || isTeacher">
            <van-button class="md:w-1/2 w-full" type="primary" to="/createClass/new">建立新课程</van-button>
        </div>
        <div class="w-full flex flex-col items-center mb-12 px-4" v-if="isStudent">
            <van-button class="md:w-1/2 w-full" type="primary" @click="goViewStudentHours()">查看我的课时</van-button>
        </div>

        <div class="md:w-1/2 w-full mb-12 px-4 justify-center">
            <span v-for="(tag, index) in filterTags" :key="index" class="inline-block">
                <van-tag v-if="tag.pick" round type="success" size="large" class="m-4 cursor-pointer"
                    @click="pickTag(tag)">{{ tag[langCode] }}</van-tag>
                <van-tag v-else round color="#aaaaaa" size="large" class="m-4 cursor-pointer" @click="pickTag(tag)">{{
        tag[langCode] }}</van-tag>
            </span>
        </div>

        <div class="md:w-1/2 w-full mb-8" v-for="(c, index) in classList" :key="c.classId">
            <class-list-card :classDetails="c" :classIndex="index" :is-teacher="isTeacher" :is-manager="isManager"
                :is-student="isStudent" @pick-teacher="teacherPicker" @go-attendance="goAttendance"
                @go-sessions="goSessions" @change-join="changeFlag($event, c.classId, 'join')"
                @change-search="changeFlag($event, c.classId, 'search')"
                @change-admit="changeFlag($event, c.classId, 'admit')" @delete-class="deleteClass(c, index)"
                @quit-class="quitClass(c, index)" @pay-class="showActionSheet(c)" @check-trans="checkTrans(c.classId)"
                @upload-image="uploadImage(c.classId)" :lang-code="langCode" :can-manage-student="canManageStudent" />
        </div>
    </div>
    <teacher-picker ref="teacherPickerRef" @finish="gotTeachers" />
    <van-action-sheet v-model:show="showPicker" :actions="pickerActions" @select="onPick" :description="pickerTitle"
        :confirm-button-text="lang[langCode].confirm" :cancel-button-text="lang[langCode].cancel" />
    <pay-action-sheet ref="actionsRef" v-if="isStudent" />
    <upload-image ref="uploadImageRef" v-if="isManager || isTeacher" />
</template>

<script>
import lang from "@/langs/PageClassListLang.js";
import {
    ref,
    computed,
    onMounted
} from "vue";
import {
    useStore
} from "vuex";
import {
    useRouter,
    useRoute
} from "vue-router";
import {
    Button,
    Toast,
    Notify,
    ActionSheet,
    Dialog,
    Tag
} from "vant";
import UserTypes from "@/static_values/StaticUserTypes.js";
import TeacherPicker from "@/components/dialog/TeacherPicker.vue";
import ClassListCard from "@/components/list/ClassListCard.vue";
import PayActionSheet from "@/components/dialog/PayActionSheet.vue";
import UploadImage from "@/components/dialog/UploadClassImage.vue";
import ListClass from "@/asyncs/ListClass.js";
import GetSession from "@/asyncs/GetSession.js";
import UpdateClassTeachers from "@/asyncs/UpdateClassTeachers.js";
import SetFlag from "@/asyncs/SetClassFlag.js";
import DeleteClass from "@/asyncs/DeleteClass.js";
import QuitClass from "@/asyncs/QuitClass.js";
import CheckTransaction from "@/asyncs/CheckTransaction.js";

export default {
    name: "PageClassList",
    components: {
        [Button.name]: Button,
        [ActionSheet.name]: ActionSheet,
        [Tag.name]: Tag,
        TeacherPicker,
        ClassListCard,
        PayActionSheet,
        UploadImage
    },
    setup() {
        const store = useStore();
        store.commit("showBack");
        store.commit("showMenu");
        store.commit("setClassDetails", null);
        const router = useRouter();
        const route = useRoute();
        const classList = ref([]);
        const classListIndexCopy = [];
        const isManager = ref(false);
        const isTeacher = ref(false);
        const isStudent = ref(false);
        onMounted(() => {
            GetSession({
                store,
                router,
                route
            }, async function () {
                isManager.value = UserTypes.manager.value === store.state.user.userType;
                isTeacher.value = UserTypes.teacher.value === store.state.user.userType;
                isStudent.value = UserTypes.student.value === store.state.user.userType;
                store.commit("setPageTitle", lang[store.state.lang].title);
                const [list, studentState] = await ListClass();
                if (!list) {
                    return;
                }
                classList.value = list;
                classList.value.sort(function (a, b) {
                    const dateOne = a.classDates.start.year * 10000 + a.classDates.start.month * 100 + a.classDates.start.day;
                    const dateTwo = b.classDates.start.year * 10000 + b.classDates.start.month * 100 + b.classDates.start.day;
                    return dateTwo - dateOne;
                });
                classList.value.forEach(function (c) {
                    if (isStudent.value) {
                        if (studentState[c.classId]) {
                            c.studentAdmit = studentState[c.classId].valid;
                        }
                    }
                    classListIndexCopy.push(c);
                });
            })
        });
        const teacherPickerRef = ref(null);
        var classToUpdate = null;
        const teacherPicker = function (classIndex) {
            teacherPickerRef.value.showPop(true);
            classToUpdate = classList.value[classIndex];
        };
        const gotTeachers = function (teachers) {
            (async () => {
                const err = await UpdateClassTeachers(classToUpdate.classId, teachers);
                if (err) {
                    Toast.fail("老师设定失败");
                } else {
                    classToUpdate.teachers = teachers;
                    classToUpdate = null;
                }
            })();
        };

        const showPicker = ref(false);
        const pickerTitle = ref("");
        const pickerActions = ref([]);
        const onPick = function (action) {
            router.push(action.value);
        };
        const goAttendance = function (actions) {
            pickerTitle.value = "请选择要查看的月份统计";
            pickerActions.value = actions;
            showPicker.value = true;
        };
        const goSessions = function (actions) {
            pickerTitle.value = lang[store.state.lang].titleLesson;
            pickerActions.value = actions;
            showPicker.value = true;
        };

        const changeFlag = async function (event, classId, flag) {
            const error = await SetFlag(classId, flag, event);
            if (error) {
                Toast.fail(error);
            }
        };
        const deleteClass = async function (classData, index) {
            try {
                await Dialog.confirm({
                    title: "删除课程",
                    message: `确定要删除 [${classData.className}] 课程吗？`,
                    confirmButtonColor: "#ee0a24"
                });
            } catch (e) {
                console.log(e);
                return;
            }
            const error = await DeleteClass(classData.classId);
            if (error) {
                Toast.fail(error);
            } else {
                classList.value.splice(index, 1);
            }
        };
        const quitClass = async function (classData, index) {
            try {
                await Dialog.confirm({
                    title: lang[store.state.lang].quitTitle,
                    message: lang[store.state.lang].quitMessage.replace("@@", classData.className),
                    confirmButtonColor: "#ee0a24",
                    confirmButtonText: lang[store.state.lang].confirm,
                    cancelButtonText: lang[store.state.lang].cancel
                });
            } catch (e) {
                console.log(e);
                return;
            }
            const ok = await QuitClass(classData.classId, classData.className,
                store.state.user.userId, store.state.user.profile.showName,
                store.state.user.userId);
            if (ok) {
                Notify({
                    type: "success",
                    message: lang[store.state.lang].quitSuccess
                });
                classList.value.splice(index, 1);
                if (classData.classDates.cost > 0) {
                    store.state.user.credit += classData.classDates.cost;
                }
            }
        };
        const actionsRef = ref(null);
        const showActionSheet = async function (c) {
            const paid = await CheckTransaction(c.classId);
            if (paid[0]) {
                Notify({
                    type: "warning",
                    message: "您已支付了此课程的费用!"
                });
                c.payPlans = [];
            } else {
                actionsRef.value.showActions(c, store.state.user.userId);
            }

        };
        const checkTrans = function (classId) {
            router.push("/studentDetails/" + store.state.user.userId + "/pay/" + classId);
        };

        const uploadImageRef = ref(null);
        const uploadImage = function (classId) {
            uploadImageRef.value.showDialog(classId);
        };

        const filterTags = ref([{
            id: "now",
            zh: "未结束课程",
            en: "Ongoing",
            pick: false
        },
        {
            id: "end",
            zh: "已结束课程",
            en: "Ended",
            pick: false
        },
        {
            id: "mo",
            zh: "周一课程",
            en: "Mondays",
            pick: false
        },
        {
            id: "tu",
            zh: "周二课程",
            en: "Tuesdays",
            pick: false
        },
        {
            id: "wd",
            zh: "周三课程",
            en: "Wednesday",
            pick: false
        },
        {
            id: "th",
            zh: "周四课程",
            en: "Thursdays",
            pick: false
        },
        {
            id: "fr",
            zh: "周五课程",
            en: "Fridays",
            pick: false
        },
        {
            id: "sa",
            zh: "周六课程",
            en: "Saturdays",
            pick: false
        },
        {
            id: "su",
            zh: "周日课程",
            en: "Sundays",
            pick: false
        }
        ]);
        const pickTag = function (tag) {
            tag.pick = !tag.pick;
            refreshList();
        };
        const refreshList = function () {
            console.log("refreshList");
            const today = new Date();
            classList.value.splice(0, classList.value.length);
            classListIndexCopy.forEach(function (c) {
                let show = true;
                filterTags.value.forEach(function (tag) {
                    if (tag.pick) {
                        if (tag.id === "now") {
                            const classEnd = new Date(c.classDates.end.year, c.classDates.end.month - 1, c.classDates.end.day);
                            if (classEnd.getTime() <= today.getTime()) {
                                show = false;
                            }
                        }
                        if (tag.id === "end") {
                            const classEnd = new Date(c.classDates.end.year, c.classDates.end.month - 1, c.classDates.end.day);
                            if (classEnd.getTime() > today.getTime()) {
                                show = false;
                            }
                        }
                        if (tag.id === "tu") {
                            let hasTuesday = false;
                            c.classTimes.forEach(function (time) {
                                if (time.day === 2) {
                                    hasTuesday = true;
                                }
                            });
                            if (!hasTuesday) {
                                show = false;
                            }
                        }
                        if (tag.id === "wd") {
                            let hasWednesday = false;
                            c.classTimes.forEach(function (time) {
                                if (time.day === 3) {
                                    hasWednesday = true;
                                }
                            });
                            if (!hasWednesday) {
                                show = false;
                            }
                        }
                        if (tag.id === "th") {
                            let hasThursday = false;
                            c.classTimes.forEach(function (time) {
                                if (time.day === 4) {
                                    hasThursday = true;
                                }
                            });
                            if (!hasThursday) {
                                show = false;
                            }
                        }
                        if (tag.id === "fr") {
                            let hasFriday = false;
                            c.classTimes.forEach(function (time) {
                                if (time.day === 5) {
                                    hasFriday = true;
                                }
                            });
                            if (!hasFriday) {
                                show = false;
                            }
                        }
                        if (tag.id === "sa") {
                            let hasSaturday = false;
                            c.classTimes.forEach(function (time) {
                                if (time.day === 6) {
                                    hasSaturday = true;
                                }
                            });
                            if (!hasSaturday) {
                                show = false;
                            }
                        }
                        if (tag.id === "mo") {
                            let hasMonday = false;
                            c.classTimes.forEach(function (time) {
                                if (time.day === 1) {
                                    hasMonday = true;
                                }
                            });
                            if (!hasMonday) {
                                show = false;
                            }
                        }
                        if (tag.id === "su") {
                            let hasSunday = false;
                            c.classTimes.forEach(function (time) {
                                if (time.day === 0) {
                                    hasSunday = true;
                                }
                            });
                            if (!hasSunday) {
                                show = false;
                            }
                        }
                    }
                });
                if (show) {
                    classList.value.push(c);
                }
            });
        };

        const goViewStudentHours = function () {
            router.push("/studentClass/" + store.state.user.userId);
        };

        return {
            lang,
            langCode: computed(() => store.state.lang),
            classList,
            teacherPickerRef,
            teacherPicker,
            gotTeachers,
            showPicker,
            pickerActions,
            goAttendance,
            onPick,
            pickerTitle,
            goSessions,
            user: computed(() => store.state.user),
            isManager,
            isTeacher,
            isStudent,
            canManageStudent: computed(() => store.state.user.manStudent),
            changeFlag,
            deleteClass,
            quitClass,
            showActionSheet,
            actionsRef,
            checkTrans,
            uploadImageRef,
            uploadImage,
            filterTags,
            pickTag,
            goViewStudentHours
        };
    }
}
</script>
