<template>
<van-dialog v-model:show="show" title="教师选择" show-cancel-button @confirm="confirm()" :confirm-button-color="'#ee0a24'">
    <van-divider />
    <div class="flex flex-col overflow-y-scroll my-max-h">
        <div v-for="(teacher, index) in teachers" :key="index" class="flex mb-7 px-3">
            <van-image width="6rem" height="6rem" fit="cover" :radius="10" :src="Env.userPath + teacher.icon" class="mr-3 cursor-pointer" :class="{'picked': teacher.picked, 'not-picked': !teacher.picked}" @click="pick(teacher)" />
            <div class="w-56">
                <div class="mb-2 flex items-center">
                    <span class="font-bold" :class="{'text-green': teacher.picked}">{{teacher.name}}</span>
                    <van-tag v-if="teacher.picked" class="ml-4 cursor-pointer" type="danger" mark plain @click="changeTeacherType(teacher)">{{TeacherTypes[teacher.type]["zh"]}}</van-tag>
                </div>
                <van-tag class="mr-3 mb-1" :color="TagTypes.personal.color" v-for="(tag, index) in teacher.tags.personal" :key="index">{{tag}}</van-tag>
                <van-tag class="mr-3 mb-1" :color="TagTypes.system.color" v-for="(tag, index) in teacher.tags.system" :key="index">{{tag}}</van-tag>
            </div>
        </div>
    </div>
</van-dialog>
</template>

<script>
import {
    ref,
    onMounted
} from "vue";
import {
    Image as VanImage,
    Tag,
    Dialog,
    Divider
} from "vant";
import ListTeacher from "@/asyncs/ListTeacher.js";
import TeacherTypes from "@/static_values/StaticTeacherTypes.js";
import Env from "@/logics/Envs.js";
import TagTypes from "@/static_values/StaticTagTypes.js";

export default {
    components: {
        [Dialog.Component.name]: Dialog.Component,
        [VanImage.name]: VanImage,
        [Tag.name]: Tag,
        [Divider.name]: Divider
    },
    setup(props, {
        emit
    }) {
        const teachers = ref([]);
        onMounted(async function () {
            const list = await ListTeacher(true, true);
            if (!list) {
                return;
            }
            teachers.value = list.map(function (a) {
                return {
                    id: a.userId,
                    name: a.profile.showName,
                    icon: a.profile.icon,
                    tags: a.meta.tags
                };
            });
        });

        const show = ref(false);
        const showPop = function (clean) {
            show.value = true;
            if (clean) {
                teachers.value.forEach(function (teacher) {
                    teacher.picked = false;
                    delete teacher.type;
                });
            }
        };
        const confirm = function () {
            const chosen = [];
            teachers.value.forEach(function (teacher) {
                if (teacher.picked) {
                    chosen.push({
                        id: teacher.id,
                        name: teacher.name,
                        icon: teacher.icon,
                        type: teacher.type
                    });
                }
            });
            emit("finish", chosen);
            show.value = false;
        };

        const pick = function (teacher) {
            teacher.picked = !teacher.picked;
            if (teacher.picked) {
                teacher.type = 1;
            } else {
                delete teacher.type;
            }
        };

        const changeTeacherType = function (teacher) {
            if (teacher.type >= 3) {
                teacher.type = 1;
                return;
            }
            teacher.type += 1;
        };

        return {
            Env,
            TagTypes,
            show,
            teachers,
            showPop,
            pick,
            confirm,
            changeTeacherType,
            TeacherTypes
        };
    }
}
</script>

<style scoped>
.my-max-h {
    max-height: 60vh;
}
</style>
