<template>
    <van-dialog v-model:show="show" title="上传封面" show-cancel-button confirm-button-text="上传"
        :confirm-button-color="'#ee0a24'" @confirm="confirm()">
        <van-divider />
        <div class="text-center my-4">
            <van-uploader v-model="fileList" :after-read="loadIcon" :max-count="1" preview-size="128px"
                :max-size="1500 * 1024" @oversize="iconOversize" :before-read="checkIcon" />
        </div>
    </van-dialog>
</template>

<script>
import Env from "@/logics/Envs.js";
import {
    ref
} from "vue";
import {
    Dialog,
    Uploader,
    Toast,
    Divider
} from "vant";
import axios from "axios";

export default {
    components: {
        [Dialog.Component.name]: Dialog.Component,
        [Uploader.name]: Uploader,
        [Divider.name]: Divider
    },
    props: {
        confirmCallback: Function
    },
    setup() {
        const show = ref(false);
        const fileList = ref([]);
        let classId = null;

        const showDialog = function (cid) {
            show.value = true;
            fileList.value = [];
            classId = cid;
        };

        const loadIcon = function () {
            if (fileList.value.length > 1) {
                fileList.value.shift();
            }
        };

        const iconOversize = function () {
            Toast("文件大小不能超过 1.5 MB");
        };

        const checkIcon = function (file) {
            if (file.type !== "image/jpeg" &&
                file.type !== "image/png" &&
                file.type !== "image/gif" &&
                file.type !== "image/svg+xml") {
                Toast("请上传图片文件");
                return false;
            }
            return true;
        };

        const confirm = async function () {
            const form = new FormData();
            form.append("file", fileList.value[0].file);
            form.append("classId", classId);

            const waitingToast = Toast.loading({
                message: "正在上传图片...",
                forbidClick: true,
                duration: 0
            });

            const res = await axios.post(Env.apiPath + "uploadClassImage", form);
            waitingToast.clear();
            if (res.data.error) {
                Toast.fail(res.data.error);
            } else {
                Toast.success("上传成功");
            }
        };

        return {
            show,
            showDialog,
            fileList,
            loadIcon,
            iconOversize,
            checkIcon,
            confirm
        };
    }
}
</script>
