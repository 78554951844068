export default function (start, end, gotMonth) {
    //month: 1...12
    var startYear = start[0];
    var startMonth = start[1];
    var endYear = end[0];
    var endMonth = end[1];

    while (startYear <= endYear) {
        if (startYear < endYear) {
            gotMonth(startYear, startMonth);
        } else if (startYear === endYear && startMonth <= endMonth) {
            gotMonth(startYear, startMonth);
        }
        startMonth += 1;
        if (startMonth >= 13) {
            startMonth = 1;
            startYear += 1;
        }
    }
}